import httpService from './http.service';


const getTenants = () => httpService
    .get(`/tenant/all`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getTenants,
};