import React from 'react';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

const drawerColor = process.env.REACT_APP_ENV_RIBBON_COLOR === 'development' ? '#2B2F3A' : '#FAFAFA';

const theme = createTheme({
  palette: {
    base: {
      white: '#FFFFFF',
      100: '#EEEEEE',
      200: '#DEE2E5',
      300: '#ADB0B8',
      400: '#95989A',
      500: '#60636B',
      600: '#6A7081',
      700: '#2B2F3A',
      800: '#1B1B28',
    },
    primary: {
      yellow: '#FDBA45',
      main: '#50A933',
      red: '#F34734',
    },
    secondary: {
      main: '#5985EE',
      lightPink: '#FBC1BA',
      lightBrown: '#FFB787',
      lightYellow: '#FFDA98',
      brown: '#FF6F00',
      darkYellow: '#E99708',
    },
    background: {
      default: '#EEEEEE',
      drawer: drawerColor,
      dark: '#000000',
      paper: '#FFFFFF',
      // paper: 'blue',
      symbol: '#16BBCF',
    },
    shadow: {
      main: '0 3px 5px rgba(0, 0, 0, 0.15)',
      light: '3px 3px 5px rgba(0, 0, 0, 0.05)',
    },
    icon: {
      red: '#F34734',
      green: '#46CC93',
      yellow: '#FDBA45',
      red2d: '2px dotted #F34734',
      green2d: '2px dotted #46CC93',
      yellow2d: '2px dotted #FDBA45'
    },
    parking: {
      10: '#FFF2DC',
      11: '#FFCC80',
      12: '#FFA23A',
      13: '#FF9116',
      14: '#EE8400',
      15: '#DF7600',
      20: '#FFF9E2',
      21: '#FFF0B1',
      22: '#FFE278',
      23: '#FFC902',
      24: '#FFBE00',
      25: '#DE9500',
      30: '#E1FDEF',
      31: '#90EFC0',
      32: '#60DFA6',
      33: '#1FBC70',
      34: '#18A761',
      35: '#10864B',
      40: '#E3FCFF',
      41: '#B1F3FA',
      42: '#13CEEA',
      43: '#08BFDF',
      44: '#0CA5C0',
      45: '#038299',
      50: '#F1EDFF',
      51: '#C1B6F2',
      52: '#8772E5',
      53: '#6954C0',
      54: '#4F3A9E',
      55: '#422799',
      60: '#FBEAF5',
      61: '#FAAFE2',
      62: '#D949A9',
      63: '#B52D88',
      64: '#982071',
      65: '#7D165B',
    },
  },
  typography: {
    h1: {
      fontSize: 40,
      fontWeight: 500,
      lineHeight: '53px',
      letterSpacing: 0,
    },
    h2: {
      fontSize: 26,
      fontWeight: 400,
      lineHeight: '35px',
      letterSpacing: 0,
    },
    h3: {
      fontSize: 22,
      fontWeight: 400,
      lineHeight: '30px',
      letterSpacing: 0,
    },
    h4: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 0.5,
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0,
    },
    h6: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '21px',
      letterSpacing: 0,
    },
    body1: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: 0.06,
    },
    subtitle1: {
      fontSize: 13,
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: 0,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '19px',
      letterSpacing: 0,
    },
    button: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '19px',
      letterSpacing: 0,
      textTransform: 'unset',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      fontStyle: 'italic',
      letterSpacing: 0,
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: 0.5,
    },
    h9: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '19px',
      fontStyle: 'italic',
      letterSpacing: 0,
    },
    h11: {
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: 0,
    },
  },

});


export default props => (
    <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
);
