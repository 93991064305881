import types from '../actionTypes';
import reservationService from '../../services/reservation.service';

export const createReservation = (reservation) => (dispatch, getState) => {
    if (getState().reservationData.isCreating) {
        return;
    }

    dispatch({
        type: types.RESERVATION_CREATE_REQUEST,
    });

    return reservationService.createReservation(reservation)
        .then(() => {
            dispatch({
                type: types.RESERVATION_CREATE_SUCCESS,
                payload: {reservation},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateReservation = (reservation) => (dispatch, getState) => {
    if (getState().reservationData.isUpdating) {
        return;
    }

    dispatch({
        type: types.RESERVATION_UPDATE_REQUEST,
    });

    return reservationService.updateRole(reservation)
        .then(() => {
            dispatch({
                type: types.RESERVATION_UPDATE_SUCCESS,
                payload: {reservation},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchReservations = (dateFrom, dateTo) => (dispatch, getState) => {
    if (getState().reservationData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.RESERVATION_FETCH_REQUEST,
    });

    return reservationService.getReservations(dateFrom, dateTo)
        .then((reservations) => {
            dispatch({
                type: types.RESERVATION_FETCH_SUCCESS,
                payload: { reservations },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RESERVATION_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
