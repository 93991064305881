import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/icons/GroupAddOutlined";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import reservation from '.';

const useStyles = makeStyles((theme) => ({
    container: {
        // marginTop: theme.spacing(3.75),
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'flex-start',
    },
    place: {
        top: '-30px',
        width: 178,
        height: 20,
        color: '#fff',
        background: 'rgb(107, 187, 174)',
        borderRadius: '0px 0px 10px 10px',
    },
    attachment: {
        height: 120,
        padding: '15px',
      },
    reservationRow: {
        borderBottom: "1px solid #dee2e5",
        padding: 10,
    },
    attachmentContainer: {
        margin: theme.spacing(1),
        borderRadius: '10px',
    },
}));

const formatDate = (date) => {
    var options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString("cs-CS", options);
}

const ReservationList = (props) => {
    const classes = useStyles();

    const {
        reservations,
        handleDetail,
    } = props;

    const handleDetailRow = (reservationId) => () => {
        handleDetail(reservationId);
    }

    const showItem = (item) => {
        console.log(item);
    }

    const renderItems = (items) => {
        return items.map((item) => (
            <Paper elevation={3} key={item.bike.bikeId} onClick={handleDetailRow(item.reservationId)}  className={classes.attachmentContainer}>
                <Grid item xs={2}>
                    <img src={`data:image/png;base64,${item.bike.img}`}
                        className={classes.attachment}
                        alt={showItem(item.bike)}
                    />
                    <div className={classes.place}>{item.bike.stand.name}</div>
                </Grid>
            </Paper>
        ));
    }

    const renderRow = () => {
        return reservations.map((item) => (
            <Grid container spacing={0} key={item.reservationId}  className={classes.reservationRow}>
                <Grid item xs={1} style={{paddingTop: '50px', }}>
                    <div><Icon /></div>
                    <div><Typography variant='caption'>{formatDate(new Date(item.creationDate))}</Typography></div>
                </Grid>
                <Grid item xs={4} style={{paddingTop: '40px'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='h3'>{item.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0}>
                                <Grid item xs={5}>
                                    <Typography variant='h6' style={{color: '#6BBBAE'}}>{formatDate(new Date(item.dateFrom))}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <ArrowRightAltIcon />
                                 </Grid>
                                 <Grid item xs={5}>
                                     <Typography variant='h6' style={{color: '#6BBBAE'}}>{formatDate(new Date(item.dateTo))}</Typography>
                                 </Grid>
                             </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} >
                    {item.items &&
                        <Grid container spacing={0}>
                            {renderItems(item.items)}
                        </Grid>
                    }
                </Grid>
            </Grid>
        ));
    }

    return (
        <div className={classes.container}>
            <div className={classes.list}>
                {reservations && renderRow()}
            </div>
        </div>
    );
}

export default ReservationList;