import httpService from './http.service';


const getReservations = (dateFrom, dateTo) => httpService
    .get(`/reservation/all?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const createReservation = (reservation) => httpService
    .post(`/reservation`, reservation)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const updateReservation = (reservation) => httpService
    .put(`/reservation`, reservation)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    getReservations,
    createReservation,
    updateReservation,
};
