import './App.css';
import {makeStyles} from '@material-ui/core/styles';
import { TopBar } from './components/layout';
import Reservation from './containers/reservation'
import MobileManagement from './containers/mobile'
import Administration from './containers/administration';
import RoleAdminitration from './containers/administration/RoleAdministration'
import UserAdminitration from './containers/administration/UserAdministration'
import {Navigate, Routes, Route} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      color: theme.palette.base[800],
      height: '100%',
  },
  content: {
      flexGrow: 1,
      height: '100%',
      overflow: 'auto',
  },
  appBarSpacer: {
    height: 50,
    ...theme.mixins.toolbar,
  },
  appContent: {
    height: 'calc(100% - 70px)',
    padding: theme.spacing(1.875),
    paddingTop: 60,
    '& > .scrollbar-container': {
        padding: theme.spacing(1.875),
    },
  },
}));

function App() {
  const classes = useStyles();
  return (
    <div className="App">
      <div className={classes.root}>
      <TopBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer}/>
        <div className={classes.appContent}>
          <Routes >
            <Route path={`/${process.env.REACT_APP_PATH}`} element={ <Navigate to={`/${process.env.REACT_APP_PATH}/reservation`} /> } />
            <Route exact path={'/'} element={<Reservation />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/reservation`} element={<Reservation />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/mobile`} element={<MobileManagement />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration`} element={<Administration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/rolelist`} element={<RoleAdminitration />} />
            <Route exact path={`/${process.env.REACT_APP_PATH}/administration/userlist`} element={<UserAdminitration />} />
          </Routes>
        </div>
      </main>
      </div>
    </div>
  );
}

export default App;

