import types from '../actionTypes';

const INITIAL_STATE = {
    isFetchingAllList: false,
    isFetchingAvailableList: false,
    errors: '',
    bike: [],
    availableBike: [],
    availableBikeFrom: null,
    availableBikeTo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.BIKE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAllList: false,
                bike: action.payload.bikes,
            };
        case types.BIKE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAllList: false,
                errors: action.payload.error,
            };
        case types.BIKE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAllList: true,
            };
        case types.BIKE_AVAILABLE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                availableBike: action.payload.bikes,
                availableBikeFrom: action.payload.dateFrom,
                availableBikeTo: action.payload.dateTo,
            };
        case types.BIKE_AVAILABLE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.BIKE_AVAILABLE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };
        default:
            return state;
    }
};
