import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
  },
  root: {
    width: '100%',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 30,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  inputField: {
    padding: theme.spacing(0.75, 0, 0.75, 2),
    fontSize: 14,
  },
  menu: {
    position: 'absolute',
    top: theme.spacing(6),
    left: 0,
    zIndex: 100,
    width: 350,
    maxHeight: 300,
    overflowY: 'auto',
    fontSize: 14,

  },
  menuLabel: {
    padding: theme.spacing(1.5),
    fontSize: theme.spacing(1.75),
    cursor: 'pointer',

    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.base.white,
    },
  },
  iconButton: {
    padding: `${theme.spacing(1)}px !important`,
    marginLeft: theme.spacing(0.5),
    background: theme.palette.base[200],

    '& svg': {
      fill: theme.palette.base.white,
    },
  },
}));

function CustomSearchInput(props) {
  const classes = useStyles();

  const {
    disabled,
    placeholder,
    menuData,
    handleChange,
    menuItemClick,
    showIcon,
    initValue,
  } = props;

  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const handleClickOutside = () => {
    setShowMenu(false);
  };

  const [value, setValue] = useState('');
  const onChange = (event) => {
    setSearchTerm(event.target.value);

    setShowMenu(true);
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchIconClick();
    }
  }

  const handleSearchIconClick = () => {
    if ( menuData.length === 1 ) {
      const selectedItem = menuData[0];
      menuItemClick(selectedItem.value);
      setShowMenu(false);
      setValue(selectedItem.label);
    }

  }

  const handleMenuItemClick = (value) => () => {
    console.log('handleMenuItemClick', value);
    const selectedItem = menuData.find((item) => item.value === value);
    menuItemClick(value);
    setShowMenu(false);
    setValue(selectedItem.label);
  };

  useEffect(
    () => {
      if ( initValue )
        setValue(initValue);
    }, [initValue]
  );

  // Our hook
  function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
      () => {
        // Set debouncedValue to value (passed in) after the specified delay
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);

        // Return a cleanup function that will be called every time ...
        // ... useEffect is re-called. useEffect will only be re-called ...
        // ... if value changes (see the inputs array below). 
        // This is how we prevent debouncedValue from changing if value is ...
        // ... changed within the delay period. Timeout gets cleared and restarted.
        // To put it in context, if the user is typing within our app's ...
        // ... search box, we don't want the debouncedValue to update until ...
        // ... they've stopped typing for more than 500ms.
        return () => {
          clearTimeout(handler);
        };
      },
      // Only re-call effect if value changes
      // You could also add the "delay" var to inputs array if you ...
      // ... need to be able to change that dynamically.
      [value] 
    );

    return debouncedValue;
  }

    // State and setter for search term
    const [searchTerm, setSearchTerm] = useState('');
    // State and setter for search results
    const [results, setResults] = useState([]);
    // State for search status (whether there is a pending API request)
    const [isSearching, setIsSearching] = useState(false);
  
    // Now we call our hook, passing in the current searchTerm value.
    // The hook will only return the latest value (what we passed in) ...
    // ... if it's been more than 500ms since it was last called.
    // Otherwise, it will return the previous value of searchTerm.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 300);
  
    // Here's where the API call happens
    // We use useEffect since this is an asynchronous action
    useEffect(
      () => {
        // Set isSearching state
        setIsSearching(true);
        // Fire off our API call
        // searchCharacters(debouncedSearchTerm).then(results => {
        //   // Set back to false since request finished
        //   setIsSearching(false);
        //   // Set results state
        //   setResults(results);
        // });
        handleChange(debouncedSearchTerm);
      },
      // This is the useEffect input array
      // Our useEffect function will only execute if this value changes ...
      // ... and thanks to our hook it will only change if the original ...
      // value (searchTerm) hasn't changed for more than 500ms.
      [debouncedSearchTerm]
    );

  return (
    <ClickAwayListener onClickAway={handleClickOutside}>
      <div className={classes.wrapper}>
        <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'id no.', className: classes.inputField }}
            disabled={disabled}
            value={value}
            onChange={onChange}
            onClick={handleShowMenu}
            onKeyDown={handleKeyDown}
          />
          { showIcon &&
            <IconButton className={classes.iconButton} aria-label="search" onClick={handleSearchIconClick}>
              <SearchIcon onClick={handleSearchIconClick}/>
            </IconButton>
          } 
       </Paper>

        {menuData.length > 0 && showMenu && (
          <Paper className={classes.menu}>
            {menuData.map((data) => (
              <div
                className={classes.menuLabel}
                key={data.key}
                onClick={handleMenuItemClick(data.value)}
              >
                {data.label}
              </div>
            ))}
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
}

CustomSearchInput.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  menuData: PropTypes.array,
  handleChange: PropTypes.func.isRequired,
  menuItemClick: PropTypes.func,
  showIcon: PropTypes.bool,
};

CustomSearchInput.defaultProps = {
  placeholder: 'ID No.',
  disabled: false,
  menuData: [],
  menuItemClick: () => {},
  showIcon: true,
};

export default CustomSearchInput;
