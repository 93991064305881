import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { getChipColor, getChipFontColor } from '../../utils';

const getColor = (type) => {
  switch (type) {
    case 'valid':
    case 'active':
    case 'assigned':
    case 'completed':
      return {
        background: '#B2EAD2',
        color: '#46CC93',
      };
    case 'passage':
      return {
        background: '#FFB787',
        color: '#FF6F00',
      };
    case 'money':
      return {
        background: '#FFDA98',
        color: '#E99708',
      };
    case 'canceled':
      return {
        background: '#D4D4D4',
        color: '#1B1B28',
      };
    case 'status':
    case 'invalid':
    case 'inactive':
    case 'unresolved':
    case 'error':
    default:
      return {
        background: '#FBC1BA',
        color: '#F24735',
      };
  }
};

const useStyles = makeStyles((theme) => ({
  typeWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  type: {
    width: (props) => props.width,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props) => getColor(props.type).background,
    borderRadius: 11,
    color: (props) => getColor(props.type).color,
    textTransform: 'uppercase',
  },
  border: {
    border: `1px solid ${theme.palette.base.white}`,
  },
}));

function EventType(props) {
  const {
    type,
    width,
    border,
    chipColor,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles({ type, width });

  return (
    <div className={classes.typeWrapper}>
      <Typography
        className={classnames(classes.type, border && classes.border)}
        style={
          chipColor
            ? { background: getChipColor(chipColor), color: getChipFontColor(chipColor) }
            : null
        }
        variant="overline"
        component="div"
      >
        {t(type.toUpperCase())}
      </Typography>
    </div>
  );
}

EventType.propTypes = {
  type: PropTypes.string.isRequired,
  width: PropTypes.number,
  border: PropTypes.bool,
  chipColor: PropTypes.string,
};

EventType.defaultProps = {
  width: 106,
  border: false,
  chipColor: '',
};

export default EventType;
