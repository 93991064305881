import React, {useCallback, useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {CustomTable} from '../../components/elements';
import { ActionButton, EventType } from '../../components/common';

  
const useStyles = makeStyles((theme) => ({
    container: {
      background: theme.palette.base.white,
      padding: theme.spacing(3.75, 2),
      boxShadow: `3px 3px 5px ${theme.palette.base[200]}`,
    },
    containerModal: {
      '& .MuiDialog-paper': {
        width: 834,
        maxWidth: 'unset',
        height: 'fit-content',
        padding: theme.spacing(4.25, 3.25),
        boxShadow: theme.palette.shadow.main,
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    mr: {
      marginRight: theme.spacing(2.5),
    },
    title: {
      marginBottom: theme.spacing(4.25),
      color: theme.palette.base[500],
  
      '& .MuiTypography-root': {
        fontSize: 24,
      },
    },
}));

const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#60636B',
    textAlign: 'center',
    padding: '20px 16px 20px 16px',
};

const MobileList = (props) => {
    const classes = useStyles();

    const {
      isLoading,
      mobileDevices,
      handleActivate
    } = props;
  
    const { t } = useTranslation();

    const columns = [
        {
          title: t('DEVICE_ID'),
          field: 'deviceId',
          cellStyle,
        },
        {
          title: t('ACTIVE'),
          field: 'state',
          cellStyle,
          render: (rowData) => (
            <EventType type={rowData.state === 'MD_A' ? 'active' : 'inactive'} />
          ),
        },
        {
          title: t('ACTIVATION_EMAIL'),
          field: 'email',
          cellStyle,
        },
        {
          title: t('REGISTRED_BY'),
          field: 'user.userName',
          cellStyle,
        },
        {
          title: t('CREATED'),
          field: 'date',
          cellStyle,
        },
        {
          title: '',
          sorting: false,
          cellStyle,
          headerStyle: {
            padding: '15px 34px 15px 34px',
          },
          render: (rowData) => {
            return <ActionButton
                action={rowData.state === 'MD_A' ? 'deactivate' : 'activate'}
                handleClick={handleActivate(rowData)}
                disabled={false}
            />
          },
        },
      ];
    
      return (
        <CustomTable
          title=""
          columns={columns}
          data={mobileDevices}
          options={{
            headerStyle: {
              padding: '15px 8px 15px 34px',
              borderTop: '1px solid #DEE2E5',
              lineHeight: '12px',
              color: '#1B1B28',
              textAlign: 'center',
            },
          }}
          isLoading={isLoading}
          rowsPerPage={20}
        />
      );

}

export default MobileList;