import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {logout} from '../../redux/actions/auth';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import logo from '../../img/kolovna_white.svg';
import LeftDrawer from './LeftDrawer';
import BreadCrumbs from './BreadCrumbs'


const useStyles = makeStyles((theme) => ({
    appBar: {
        height: 118,
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.background.dark,
        boxShadow: 'none',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    toolbar: {
        height: 118,
        paddingRight: theme.spacing(3), // keep right padding when drawer closed
        color: theme.palette.base['white'],
    },
    breadCrumbs: {
        marginTop: 0,
        height: 50,
        padding: 10,
        textAlign: 'left',
        backgroundColor: 'rgb(107, 187, 174)',
        display: 'flex',

    },
    divlogo: {
        marginTop: '15px',
        textAlign: 'left'
    },
    divlogo2: {
        marginTop: '15px',
        textAlign: 'center'
    },
    logo2: {
        maxHeight: '27px'
    },
    username: {
        textAlign: 'right',
        padding: theme.spacing(0.5, 2),
    },
    signOut: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '16px',
        color: '#C8C8C8',
        textTransform: 'none',

        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.base[200],
        },
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.base[600],
    },
}));

const TopBar = (props) => {
    const {user} = props;

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <div className={classes.divlogo}>
                            <LeftDrawer></LeftDrawer>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className={classes.divlogo2}>
                            <img src={logo} className={classes.logo2} />
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        {user && (
                            <div className={classes.username}>
                                <Typography variant="body1">
                                    { user.lastName &&  user.firstName && `${user.firstName} ${user.lastName}` }
                                    { user.lastName &&  !user.firstName && `${user.lastName}` }
                                    { !user.lastName && user.userName && `${user.userName}` }
                                </Typography>
                                <Button
                                    color="primary"
                                    variant="text"
                                    className={classes.signOut}
                                    onClick={props.logout}
                                >
                                    {t('SIGN_OUT')}
                                </Button>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </Toolbar>
            <BreadCrumbs/>
        </AppBar>
    )
}

TopBar.propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
};

TopBar.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
