import {Loading} from '../../components/elements';
import ToolBar from './ToolBar';
import PerfectScrollbar from "react-perfect-scrollbar";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';
import MobileList from './MobileList';
import {fetchDevices, updateState} from '../../redux/actions/mobiledevice'

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
    container2: {
        height: '850px',
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
      },
}));

const MobileManagement = (props) => {
    const classes = useStyles();

    const {
        user,
        fetchDevices,
        updateState,
        mobileDevices
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedDevice, setIsFetchedDevice] = useState(false);
    const [filteredDevice, setFilteredDevice] = useState(false);

    useEffect(() => {
        if ( !isFetchedDevice ) {
            setIsLoading(true);
            fetchDevices().then(()=>{
                setIsFetchedDevice(true);
                setIsLoading(false);
            }).catch((err) => {
                setIsFetchedDevice(true);
                setIsLoading(false);
            });
        }
    }, [isFetchedDevice, fetchDevices]);

    const handleFilter = (searched) => {
        if ( mobileDevices ) {
            let tmp = [];
            tmp = mobileDevices.map((item)=>{
                return {...item};
            });
            if ( searched.device ) {
                tmp = tmp.filter(item=>item.deviceId.includes(searched.device))
            }
            if ( searched.email ) {
                tmp = tmp.filter(item=>item.email.includes(searched.email))
            }
            setFilteredDevice(tmp);
        }
    }

    const handleActivate = (item) => () => {
        updateState(item.mobileDeviceId, item.state==='MD_A'?false:true);
        setIsFetchedDevice(false);
    }

    if (!user && isLoading) {
        return (
            <Loading/>
        );
    }

    return (
        <>
            <div className={classes.container}>
            <ToolBar handleFilter={handleFilter}/>
            </div>
            <div className={classes.container2}>
                <PerfectScrollbar options={{suppressScrollX: true,minScrollbarLength: 50,}}>
                    <MobileList 
                        mobileDevices={filteredDevice || mobileDevices} 
                        isLoading={isLoading} 
                        handleActivate={handleActivate}
                    />
                </PerfectScrollbar>
            </div>
        </>
    );
}

MobileManagement.propTypes = {
    user: PropTypes.object,
};

MobileManagement.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    mobileDevices: store.mobiledeviceData.mobiledevice,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchDevices,
    updateState
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileManagement);