import {makeStyles} from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {useTranslation} from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    breadCrumbs: {
        marginTop: 0,
        height: 50,
        padding: 10,
        textAlign: 'left',
        backgroundColor: 'rgb(107, 187, 174)',
        display: 'flex',

    },
}));

const BreadCrumbs = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const location = useLocation();

    const renderLocation = () => {
        const pathName = location.pathname.substring(process.env.REACT_APP_PATH.length+2);
        let path;
        return (
            pathName.split('/').map( (item) => {
            if ( !path ) 
                path = item;
            else
                path = path + '/' + item;

            return (
                <Link to={`/${process.env.REACT_APP_PATH}/${path}`} classes={classes.link}>
                    <Typography color="textPrimary">{t(item)}</Typography>
                </Link>
            )
        }));
    }

    return(
        <div className={classes.breadCrumbs}>
            <Breadcrumbs aria-label="breadcrumb">
                {renderLocation()}
            </Breadcrumbs>        
        </div>        
    )
}

export default BreadCrumbs;