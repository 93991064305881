import httpService from './http.service';


const createRole = (role) => httpService
    .post(`/role`, role)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateRole = (role) => httpService
    .put(`/role`, role)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activeRole = (roleId, active) => httpService
    .put(`/role/${roleId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getAllRoles = () => httpService
    .get('/role/all')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const addUserToRole = (userId, roleId) => httpService
    .put(`/role/${roleId}/add/${userId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const removeUserToRole = (userId, roleId) => httpService
    .put(`/role/${roleId}/remove/${userId}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


export default {
    getAllRoles, createRole, updateRole, activeRole, addUserToRole, removeUserToRole
};
