import httpService from './http.service';


const getBikes = () => httpService
    .get(`/bike/all`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getAvailableBikes = (dateFrom, dateTo) => httpService
    .get(`/bike/available?dateFrom=${dateFrom}&dateTo=${dateTo}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

export default {
    getBikes,
    getAvailableBikes,
};