import * as React from "react";
import TextField from "@material-ui/core/TextField";
import { DateRangePicker, DateRangeDelimiter, LocalizationProvider, StaticDateRangePicker } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns"
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  markcolor: {
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: '#6BBBAE'
    },
    "& .MuiPickersDateRangeDay-rangeIntervalDayHighlight": {
       backgroundColor: '#6BCBAE'
    },
  },
  errorMessage: {
    fontSize: 13,
    color: theme.palette.primary.red,
  },
}));

const BasicDateRangePicker = (props) => {
    const {
        startText,
        endText,
        value,
        handleChange,
        showStatic,
        requiredFrom,
        errorFrom,
        requiredTo,
        errorTo,
        disabled
    } = props;

    const {t} = useTranslation();
    const classes = useStyles();

    let show=false;
    if ( showStatic ) show = true;

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DateRangePicker
            startText={startText}
            endText={endText}
            value={value}
            onChange={(newValue) => handleChange(newValue)}
            renderInput={(startProps, endProps) => (
              <React.Fragment>
              <TextField {...startProps} required={requiredFrom}
                  helperText={errorFrom?<span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>: null}
              />
              <DateRangeDelimiter> {t('TO')} </DateRangeDelimiter>
              <TextField {...endProps}  required={requiredTo} 
                  helperText={errorTo?<span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>: null}
              />
              </React.Fragment>
            )}
            className={classes.markcolor}
            disabled={disabled}
        />

        {show &&
        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={(newValue) => handleChange(newValue)}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} />
              <DateRangeDelimiter> to </DateRangeDelimiter>
              <TextField {...endProps} />
            </React.Fragment>
          )}
          className={classes.markcolor}
        />
        }

    </LocalizationProvider>
  );
}

export default BasicDateRangePicker;