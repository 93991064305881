import httpService from './http.service';


const getDevices = () => httpService
    .get('/md')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


const setActive = (id, active) => httpService
    .put(`/md/${id}/active?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    getDevices,
    setActive,
};
