import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import logo from '../../img/kolovna_emblem_bg_black.svg';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';
import SettingsApplications from '@material-ui/icons/Tune';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  list: {
    width: 50,
    backgroundColor: 'white',
  },
});

export default function LeftDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Link to={`/${process.env.REACT_APP_PATH}/reservation`} >
          <ListItem button key='Reservation'>
            <ListItemIcon>
              <EventAvailableIcon />
            </ListItemIcon>
          </ListItem>
        </Link>
        <Link to={`/${process.env.REACT_APP_PATH}/mobile`} >
          <ListItem button key='Mobile'>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
          </ListItem>
        </Link>
        <Link to={`/${process.env.REACT_APP_PATH}/administration`} >
          <ListItem button key='Admin'>
            <ListItemIcon>
              <SettingsApplications />
            </ListItemIcon>
          </ListItem>
        </Link>
      </List>
    </div>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <img src={logo} onClick={toggleDrawer(anchor, true)}/>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
