import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Add from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Chip} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(2, 1, 1, 2),
    },
    toolbarInner1: {
        width: '100%',
        border: '1px solid #E0E0E0',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: theme.palette.shadow.main,
        padding: theme.spacing(2, 1, 1, 2),

    },
    addButton: {
        backgroundColor: theme.palette.base[100],
        marginLeft: theme.spacing(10),
        padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
    },
    addIcon: {
        width: 30,
        height: 30,
    },
}));

function ToolBar(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleUserUpdate,
        selectedName,
        handleSelectedName,
        selectedUCName,
        handleSelectedUCName,
        selectedStatus,
        handleSelectedStatus,
        selectedAdminRole,
        handleSelectedAdminRole,
        selectedRole,
        handleSelectedRole,

        roles,

    } = props;

    const {t} = useTranslation();

    const statuses = [
        {
            value: 'ACTIVE',
            label: t('ACTIVE'),
            key: 'ACTIVE'
        },
        {
            value: 'INACTIVE',
            label: t('INACTIVE'),
            key: 'INACTIVE'
        },
    ]

    const admin = [
        {
            value: 'YES',
            label: t('YES'),
            key: 'YES'
        },
        {
            value: 'NO',
            label: t('NO'),
            key: 'NO'
        },
    ]

    return (
        <div className={classes.toolbar}>
            <div className={classes.toolbarInner1}>
                <form autoComplete='off'>
                    <Box mb={2} height="100%" width="100%">
                        <Grid container spacing={2} style={{height: '100%', width: '100%'}}>
                            <Grid item sm={2}>
                                <TextField
                                    type="search"
                                    label={t('NAME')}
                                    fullWidth
                                    value={selectedName || ''}
                                    onChange={handleSelectedName}
                                />

                            </Grid>
                            <Grid item sm={2}>
                                <TextField
                                    type="search"
                                    label={t('UC_NAME')}
                                    fullWidth
                                    value={selectedUCName || ''}
                                    onChange={handleSelectedUCName}
                                />

                            </Grid>

                            <Grid item sm={1}>

                                <Autocomplete
                                    multiple
                                    value={selectedStatus}
                                    onChange={(event, newValue) => {
                                        handleSelectedStatus(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="statuses"
                                    options={statuses}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('STATE')} fullWidth


                                        />
                                    )}
                                    renderOption={(option) => (
                                        <div style={{
                                            color: option.color,
                                        }}> {option.label}</div>

                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: 'black'
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>

                            <Grid item sm={2}>
                                <Autocomplete
                                    multiple
                                    value={selectedRole}
                                    onChange={(event, newValue) => {
                                        handleSelectedRole(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="role"
                                    options={roles || []}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('ROLE')} fullWidth


                                        />
                                    )}
                                    renderOption={(option) => (
                                        <div style={{
                                            color: option.color,
                                        }}> {option.label}</div>

                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: 'black'
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>

                            <Grid item sm={1}>
                                <Autocomplete
                                    multiple
                                    value={selectedAdminRole}
                                    onChange={(event, newValue) => {
                                        handleSelectedAdminRole(newValue);
                                    }}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    filterSelectedOptions
                                    id="adminRole"
                                    options={admin}
                                    getOptionLabel={(option) => {
                                        return option.label;
                                    }}
                                    style={{width: '100%'}}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('ADMIN_ROLE')} fullWidth


                                        />
                                    )}
                                    renderOption={(option) => (
                                        <div style={{
                                            color: option.color,
                                        }}> {option.label}</div>

                                    )}
                                    renderTags={(value, getTagProps) => (
                                        value.map((option, index) => {
                                                return <Chip
                                                    style={{
                                                        backgroundColor: option.color,
                                                        color: 'black'
                                                    }}
                                                    variant="outlined"
                                                    label={option.label}
                                                    {...getTagProps({index})}
                                                />
                                            }
                                        )
                                    )
                                    }
                                />
                            </Grid>

                            <Grid item sm={2}></Grid>

                            <Grid item sm={2}>
                                {isComponentVisibleForUser(UC_USER_CREATE, userUseCases) &&
                                <IconButton
                                    className={classes.addButton}
                                    edge="start"
                                    color="inherit"
                                    aria-label="add role"
                                    onClick={() => {
                                        handleUserUpdate({userId: 0}, true);
                                    }}
                                    disabled={!isComponentEnabledForUser(UC_USER_CREATE, userUseCases)}
                                >
                                    <Add className={classes.addIcon}/>
                                </IconButton>
                                }
                            </Grid>

                        </Grid>
                    </Box>
                </form>
            </div>
        </div>

    )
        ;
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

const UC_USER_CREATE = 'UC0661';

export default ToolBar;
