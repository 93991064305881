export default {
    // Set user info
    SET_USER_INFO: 'SET_USER_INFO',
    // Logout
    LOG_OUT: 'LOG_OUT',

    // Fetch code list
    ALL_CODE_FETCH_REQUEST: 'ALL_CODE_FETCH_REQUEST',
    ALL_CODE_FETCH_SUCCESS: 'ALL_CODE_FETCH_SUCCESS',
    ALL_CODE_FETCH_FAIL: 'ALL_CODE_FETCH_FAIL',
   
    // use cases
    USECASE_FETCH_REQUEST: 'USECASE_FETCH_REQUEST',
    USECASE_FETCH_SUCCESS: 'USECASE_FETCH_SUCCESS',
    USECASE_FETCH_FAIL: 'USECASE_FETCH_FAIL',

    // reservation
    RESERVATION_FETCH_REQUEST: 'RESERVATION_FETCH_REQUEST',
    RESERVATION_FETCH_SUCCESS: 'RESERVATION_FETCH_SUCCESS',
    RESERVATION_FETCH_FAIL: 'RESERVATION_FETCH_FAIL',

    RESERVATION_CREATE_REQUEST: 'RESERVATION_CREATE_REQUEST',
    RESERVATION_CREATE_SUCCESS: 'RESERVATION_CREATE_SUCCESS',
    RESERVATION_CREATE_FAIL: 'RESERVATION_CREATE_FAIL',

    RESERVATION_UPDATE_REQUEST: 'RESERVATION_UPDATE_REQUEST',
    RESERVATION_UPDATE_SUCCESS: 'RESERVATION_UPDATE_SUCCESS',
    RESERVATION_UPDATE_FAIL: 'RESERVATION_UPDATE_FAIL',

    // bike
    BIKE_FETCH_REQUEST: 'BIKE_FETCH_REQUEST',
    BIKE_FETCH_SUCCESS: 'BIKE_FETCH_SUCCESS',
    BIKE_FETCH_FAIL: 'BIKE_FETCH_FAIL',

    BIKE_AVAILABLE_FETCH_REQUEST: 'BIKE_AVAILABLE_FETCH_REQUEST',
    BIKE_AVAILABLE_FETCH_SUCCESS: 'BIKE_AVAILABLE_FETCH_SUCCESS',
    BIKE_AVAILABLE_FETCH_FAIL: 'BIKE_AVAILABLE_FETCH_FAIL',

    // place
    PLACE_FETCH_REQUEST: 'PLACE_FETCH_REQUEST',
    PLACE_FETCH_SUCCESS: 'PLACE_FETCH_SUCCESS',
    PLACE_FETCH_FAIL: 'PLACE_FETCH_FAIL',

    // code list
    CODELIST_FETCH_REQUEST: 'CODELIST_FETCH_REQUEST',
    CODELIST_FETCH_SUCCESS: 'CODELIST_FETCH_SUCCESS',
    CODELIST_FETCH_FAIL: 'CODELIST_FETCH_FAIL',

    // mobile device
    MOBILEDEVICE_FETCH_REQUEST: 'MOBILEDEVICE_FETCH_REQUEST',
    MOBILEDEVICE_FETCH_SUCCESS: 'MOBILEDEVICE_FETCH_SUCCESS',
    MOBILEDEVICE_FETCH_FAIL: 'MOBILEDEVICE_FETCH_FAIL',

    MOBILEDEVICE_UPDATE_REQUEST: 'MOBILEDEVICE_UPDATE_REQUEST',
    MOBILEDEVICE_UPDATE_SUCCESS: 'MOBILEDEVICE_UPDATE_SUCCESS',
    MOBILEDEVICE_UPDATE_FAIL: 'MOBILEDEVICE_UPDATE_FAIL',

    // users
    USERS_FETCH_REQUEST: 'USERS_FETCH_REQUEST',
    USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
    USERS_FETCH_FAIL: 'USERS_FETCH_FAIL',

    USERS_BY_ROLE_FETCH_REQUEST: 'USERS_BY_ROLE_FETCH_REQUEST',
    USERS_BY_ROLE_FETCH_SUCCESS: 'USERS_BY_ROLE_FETCH_SUCCESS',
    USERS_BY_ROLE_FETCH_FAIL: 'USERS_BY_ROLE_FETCH_FAIL',

    USER_CREATE_REQUEST: 'USER_CREATE_REQUEST',
    USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
    USER_CREATE_FAIL: 'USER_CREATE_FAIL',
    
    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAIL: 'USER_UPDATE_FAIL',

    // roles
    ROLES_FETCH_REQUEST: ' ROLES_FETCH_REQUEST',
    ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
    ROLES_FETCH_FAIL: ' ROLES_FETCH_FAIL',

    ROLES_UPDATE_REQUEST: 'ROLES_UPDATE_REQUEST',
    ROLES_UPDATE_SUCCESS: 'ROLES_UPDATE_SUCCESS',
    ROLES_UPDATE_FAIL: 'ROLES_UPDATE_FAIL',

    ROLES_ACTIVE_REQUEST: 'ROLES_ACTIVE_REQUEST',
    ROLES_ACTIVE_SUCCESS: 'ROLES_ACTIVE_SUCCESS',
    ROLES_ACTIVE_FAIL: 'ROLES_ACTIVE_FAIL',

    ROLES_CREATE_REQUEST: 'ROLES_CREATE_REQUEST',
    ROLES_CREATE_SUCCESS: 'ROLES_CREATE_SUCCESS',
    ROLES_CREATE_FAIL: 'ROLES_CREATE_FAIL',

    ROLES_ADD_ROLE_REQUEST: 'ROLES_ADD_ROLE_REQUEST',
    ROLES_ADD_ROLE_SUCCESS: 'ROLES_ADD_ROLE_SUCCESS',
    ROLES_ADD_ROLE_FAIL: 'ROLES_ADD_ROLE_FAIL',

    ROLES_REMOVE_ROLE_REQUEST: 'ROLES_REMOVE_ROLE_REQUEST',
    ROLES_REMOVE_ROLE_SUCCESS: 'ROLES_REMOVE_ROLE_SUCCESS',
    ROLES_REMOVE_ROLE_FAIL: 'ROLES_REMOVE_ROLE_FAIL',

    // tenants
    TENANT_FETCH_REQUEST: 'TENANT_FETCH_REQUEST',
    TENANT_FETCH_SUCCESS: 'TENANT_FETCH_SUCCESS',
    TENANT_FETCH_FAIL: 'TENANT_FETCH_FAIL',
};
