import types from '../actionTypes';
import tenantService from '../../services/tenant.service';

export const fetchTenants = () => (dispatch, getState) => {
    if (getState().placeData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_FETCH_REQUEST,
    });

    return tenantService.getTenants()
        .then((tenants) => {
            dispatch({
                type: types.TENANT_FETCH_SUCCESS,
                payload: { tenants: tenants },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};