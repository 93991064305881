import React from 'react';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import BasicDateRangePicker from '../../components/layout/DatePicker';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Add from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        width: '100%',
        padding: theme.spacing(2, 1, 1, 2),
        backgroundColor: theme.palette.background.paper,
    },
    addButton: {
        backgroundColor: 'rgb(107, 187, 174)',
    }
}));

const ToolBar = (props) => {
    const {
        handleFilter,
        hadleNewReservation
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();

    const [value, setValue] = useState([null, null]);

    const handleChange = (newValue) => {
        setValue(newValue);
        handleFilter(newValue);
    }

    return(
        <div classes={classes.toolbar}>
            <Grid container spacing={1} style={{height: '100%', width: '100%', paddingTop: '10px', paddingLeft: '20px'}}>
                <Grid item sm={11}>
                    <BasicDateRangePicker
                        startText={t('BEGIN')}
                        endText={t('END')}
                        value={value}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item sm={1}>
                    <div classes={classes.addButton}>
                    <IconButton
                        className={classes.addButton}
                        edge="start"
                        color="inherit"
                        aria-label="add reservation"
                        onClick={hadleNewReservation}
                    >
                        <Add className={classes.addIcon}/>
                    </IconButton>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default ToolBar;