import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authData from './auth';
import reservationData from './reservation';
import bikeData from './bike';
import placeData from './place';
import codelistData from './codelist';
import mobiledeviceData from './mobiledevice';
import rolesData from './roles';
import usersData from './users';
import codesData from './codelist';
import useCasesData from './usecases';
import tenantsData from './tenant';


export default (history) => combineReducers({
    router: connectRouter(history),
    authData,
    reservationData,
    bikeData,
    placeData,
    codelistData,
    mobiledeviceData,
    rolesData,
    usersData,
    tenantsData,
    codesData,
    useCasesData,
});
