import types from '../actionTypes';
import placeService from '../../services/place.service';

export const fetchPlaces = () => (dispatch, getState) => {
    if (getState().placeData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.PLACE_FETCH_REQUEST,
    });

    return placeService.getPlaces()
        .then((places) => {
            dispatch({
                type: types.PLACE_FETCH_SUCCESS,
                payload: { places: places },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PLACE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};