import {Loading} from '../../components/elements';
import PerfectScrollbar from "react-perfect-scrollbar";
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import ToolBar from './ToolBar';
import ReservationList from './ReservationList'
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from 'react';
import {fetchReservations, createReservation} from '../../redux/actions/reservation'
import {fetchBikes, fetchAvailableBikes} from '../../redux/actions/bike';
import {fetchPlaces} from '../../redux/actions/place'
import {fetchCodeLists} from '../../redux/actions/codelists'
import NewReservation from '../../components/modal/reservation';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
    },
    container2: {
        height: '850px',
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        borderRadius: '4px',
      },
}));

const Reservation = (props) => {
    const classes = useStyles();
    const {
        user,
        reservations,
        fetchReservations,
        bikes,
        fetchBikes,
        availableBikes,
        fetchAvailableBikes,
        places,
        fetchPlaces,
        codeLists,
        fetchCodeLists,
        createReservation,
    } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [isFetchedReservations, setIsFetchedReservations] = useState(false);
    const [isFetchedBikes, setIsFetchedBikes] = useState(false);
    const [isFetchedPlaces, setIsFetchedPlaces] = useState(false);
    const [isFetchedCodeList, setIsFetchedCodeLists] = useState(false);
    const [showNew, setShowNew] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [detailValues, setDetailValues] = useState();
    
    const [dates, setDates] = useState();

    useEffect(() => {
        if ( !isFetchedReservations ) {
            let dateFrom, dateTo;
            if ( dates && dates[0] && dates[1] ) {
                // date time zone offset calculation
                dateFrom = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000));
                dateTo = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000));
                dateFrom = dateFrom.toISOString().slice(0, 10);
                dateTo = dateTo.toISOString().slice(0, 10);
            }

            setIsLoading(true);
            fetchReservations(dateFrom?dateFrom:'', dateTo?dateTo:'').then(()=>{
                setIsFetchedReservations(true);
            }).catch((err) => {
                setIsFetchedReservations(true);
                setIsLoading(false)
            });
        }
        if ( !isFetchedBikes ) {
            setIsLoading(true);
            fetchBikes().then(()=>{
                setIsFetchedBikes(true);
            }).catch((err) => {
                setIsFetchedBikes(true);
                setIsLoading(false)
            });
        }
        if ( !isFetchedPlaces ) {
            setIsLoading(true);
            fetchPlaces().then(()=>{
                setIsFetchedPlaces(true);
            }).catch((err) => {
                setIsFetchedPlaces(true);
                setIsLoading(false)
            });
        }
        if ( !isFetchedCodeList ) {
            setIsLoading(true);
            fetchCodeLists().then(()=>{
               setIsFetchedCodeLists(true);
            }
            ).catch((err) => {
                setIsFetchedCodeLists(true);
                setIsLoading(false)
            });
        }
    }, [isFetchedReservations, fetchReservations, isFetchedBikes, fetchBikes, isFetchedPlaces, fetchPlaces, isFetchedCodeList, fetchCodeLists]);
    
    if (!user && !isLoading) {
        return (
            <Loading/>
        );
    }

    const handleFilter = (dates) => {
        setIsLoading(true);
        setDates(dates);
        let dateFrom, dateTo;
        if ( dates[0] && dates[1] ) {
            // date time zone offset calculation
            dateFrom = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000));
            dateTo = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000));
            dateFrom = dateFrom.toISOString().slice(0, 10);
            dateTo = dateTo.toISOString().slice(0, 10);
        } else {
            dateFrom = '';
            dateTo = '';
        }
        
        fetchReservations(dateFrom, dateTo).then(
        ).catch((err) => {
            setIsFetchedReservations(true);
            setIsLoading(false)
        });
    }

    const hadleNewReservation = (obj) => {
        setShowNew(true);
    }

    const handleNewClose = () => {
        setShowNew(false);
        setShowDetail(false);
        setDetailValues();
    }

    const handleNewSave = (reservation) => {
        setShowNew(false);
        let today = new Date();
        let tmp = {
            'active': true,
            'name': reservation.name,
            'creationDate': today.toISOString().slice(0, 10),
            'dateFrom': reservation.dateFrom,
            'dateTo': reservation.dateTo,
            'items': reservation.bikes.myBikes.map((item)=>{
                return {'bike':item}
            })
        };
        createReservation(tmp).then(()=>{
            setIsFetchedReservations(false);
        });
    }

    const handleDetail = (id) => {
        reservations.map((item) => {
            if ( item.reservationId === id ) {
                let bikes = [];
                item.items.map((bike)=>{
                    bikes.push(bike.bike);
                })
                setDetailValues({
                    date: [item.dateFrom, item.dateTo],
                    myBikes: bikes,
                    name: item.name
                });
            }
        })
        setShowDetail(true);
    }


    return (
        <>
            { (showNew || showDetail ) &&
                <NewReservation 
                    openNew={showNew}
                    handleClose={handleNewClose}
                    handleSave={handleNewSave}
                    places={places}
                    codeLists={codeLists}
                    showDetail={showDetail}
                    detailValues={detailValues}
                    availableBikes={availableBikes}
                    fetchAvailableBikes={fetchAvailableBikes}
                />
            }
            <div className={classes.container}>
                <ToolBar 
                    handleFilter={handleFilter}
                    hadleNewReservation={hadleNewReservation}
                    />
            </div>
            <div className={classes.container2}>
                <PerfectScrollbar options={{suppressScrollX: true,minScrollbarLength: 50,}}>
                    <ReservationList 
                        reservations={reservations}
                        handleDetail={handleDetail}
                    />
                </PerfectScrollbar>
            </div>
        </>
    );
}

Reservation.propTypes = {
    user: PropTypes.object,
};

Reservation.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    reservations: store.reservationData.reservation,
    bikes: store.bikeData.bike,
    availableBikes: store.bikeData.availableBike,
    places: store.placeData.place,
    codeLists: store.codelistData.codelist
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchReservations,
    fetchBikes,
    fetchAvailableBikes,
    fetchPlaces,
    fetchCodeLists,
    createReservation,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reservation);