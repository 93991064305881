import types from '../actionTypes';
import bikeService from '../../services/bike.service';

export const fetchBikes = () => (dispatch, getState) => {
    if (getState().bikeData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BIKE_FETCH_REQUEST,
    });

    return bikeService.getBikes()
        .then((bikes) => {
            dispatch({
                type: types.BIKE_FETCH_SUCCESS,
                payload: { bikes },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

export const fetchAvailableBikes = (dateFrom, dateTo) => (dispatch, getState) => {
    if (getState().bikeData.isFetchingAvailableList) {
        return Promise.reject();
    }

    dispatch({
        type: types.BIKE_AVAILABLE_FETCH_REQUEST,
    });

    return bikeService.getAvailableBikes(dateFrom, dateTo)
        .then((bikes) => {
            dispatch({
                type: types.BIKE_AVAILABLE_FETCH_SUCCESS,
                payload: { bikes, dateFrom, dateTo },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.BIKE_AVAILABLE_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};
