import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import {ArrowUpward, ChevronRight} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

import MaterialTable from 'material-table';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
import CustomCheckBox from '../../elements/CustomCheckBox2';

const useStyles = makeStyles((theme) => ({
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        
        alignItems: 'flex-start',
        paddingTop: '20px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '10px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
        marginLeft: '30px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
}));

function UserUpdate(props) {
    const classes = useStyles();

    const {
        userUseCases,
        roles,
        rowClickHandel,
        headerClickHandel,
        headerChecked,
        handleChange,
        updatedUser,
        error,
        readOnly,
        isNew,
        tenants,
    } = props;

    const {t} = useTranslation();

    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%" width="100%">
                    <Grid container spacing={2} sm={12}>
                        <Grid container spacing={1} sm={6} style={{marginLeft: "auto", marginRight: "auto"}}>
                            <Grid item xs sm={12} spacing={1} >
                                <Grid container spacing={2}>
                                    <Grid item xs={6} spacing={1} >
                                        <TextField
                                            style={{height: '60px'}}
                                            autoFocus
                                            helperText={error && error.firstName
                                                ?
                                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            disabled={readOnly}
                                            id="firstName"
                                            value={updatedUser.firstName || ''}
                                            onChange={handleChange('firstName', false)}
                                            label={t('FIRST_NAME')}/>
                                    </Grid>

                                    <Grid item xs={6} spacing={1} >
                                        <TextField
                                            style={{height: '60px'}}
                                            autoFocus
                                            helperText={error && error.lastName
                                                ?
                                                <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required
                                            disabled={readOnly}
                                            id="lastName"
                                            value={updatedUser.lastName || ''}
                                            onChange={handleChange('lastName', false)}
                                            label={t('LAST_NAME')}/>
                                    </Grid>
                                </Grid>

                                <TextField
                                    style={{height: '60px'}}
                                    autoFocus
                                    helperText={error && error.userName
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly || !isNew}
                                    id="userName"
                                    value={updatedUser.userName || ''}
                                    onChange={handleChange('userName', false)}
                                    label={t('LOGIN')}/>
                                <TextField
                                    style={{height: '60px'}}
                                    autoFocus
                                    helperText={error && error.email
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required
                                    disabled={readOnly}
                                    id="email"
                                    value={updatedUser.email || ''}
                                    onChange={handleChange('email', false)}
                                    label={t('EMAIL')}/>

                                <TextField
                                    style={{height: '60px'}}
                                    autoFocus
                                    helperText={error && error.password
                                        ?
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                        : null}
                                    fullWidth
                                    required={isNew}
                                    disabled={readOnly}
                                    id="password"
                                    value={updatedUser.password || ''}
                                    onChange={handleChange('password', false)}
                                    label={t('password')}
                                    type="password"/>

                                <FormControl required fullWidth disabled={false} style={{height: '60px'}}>
                                    <InputLabel id="active-label">
                                        {t('USER_ACTIVE')}
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedUser.active || ''}                                        
                                        id="active"
                                        onChange={handleChange('active', true)}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        <MenuItem value={'true'}>{t('YES')}</MenuItem>
                                        <MenuItem value={'false'}>{t('NO')}</MenuItem>
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>

                                <FormControl required fullWidth disabled={false} style={{height: '60px'}}>
                                    <InputLabel id="tenant-label">
                                        {t('TENANT')}
                                    </InputLabel>
                                    <Select
                                        autoWidth
                                        disabled={readOnly}
                                        value={updatedUser.tenantId || ''}                                        
                                        id="tenantId"
                                        onChange={handleChange('tenantId', updatedUser.tenantId)}
                                    >
                                        <MenuItem value=''>-</MenuItem>
                                        {tenants.map(item => (
                                            <MenuItem value={item.tenantId}>{item.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>  {error && error.active && (
                                        <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                    )}</FormHelperText>
                                </FormControl>

                            </Grid>
                        </Grid>
                        <Grid container alignItems="stretch" spacing={2} sm={6}
                                style={{marginLeft: "auto", marginRight: "auto"}}>

                            <Typography variant="button" className={classes.description}>
                                {t('CHOOSE_ROLE')}
                            </Typography>

                            <div className={classes.editorWrapper}>
                            
                                <MaterialTable
                                    title=""
                                    search="false"
                                    icons={{
                                        SortArrow: ArrowUpward,
                                        DetailPanel: ChevronRight,
                                        Search: Search,
                                        ResetSearch: Clear
                                    }}
                                    data={roles}

                                    columns={[
                                        { title: t('ROLE_NAME'), field: 'name' },
                                        { title: t('ROLE_ADMIN'), field: 'admin', render: rowData => (rowData.admin ? t('YES') : t('NO')), },
                                        { title: t('ROLE_ACTIVE'), field: 'active', render: rowData => (rowData.active ? t('YES') : t('NO')), },
                                    ]}

                                    options={{
                                        showTitle: false, paging:false, showTextRowsSelected:false,
                                        headerStyle: {
                                            position: "sticky",
                                            top: "0"
                                        },
                                        maxBodyHeight: "512px",
                                        
                                        toolbarButtonAlignment:"left"
                                    }}

                                    actions={readOnly?[]:[
                                        {
                                            icon: 'checked',
                                            tooltip: 'checked',
                                            onClick: (event, rowData) => {rowClickHandel(rowData)}
                                        },
                                        {
                                            icon: 'checkAll',
                                            tooltip: 'check all',
                                            onClick: (event, rowData) =>  headerClickHandel(),
                                            isFreeAction: true,
                                        },
                                    ]}

                                    localization={{
                                        header: {
                                            actions: ''
                                        },
                                    }}

                                    components={{
                                        Action: props => (
                                          <CustomCheckBox
                                            onClick={(event) => props.action.onClick(event, props.data)}
                                            color={'white'}
                                            iconNumber={props.data.name?props.data.checked:headerChecked}
                                            paddingTop={props.data.name?'0':'8px'}
                                            paddingLeft={props.data.name?'20px':'0'}
                                            width='5'
                                            height='5'
                                            text={props.data.name?'':t('CHECK_ALL')}
                                          />
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    )
}

UserUpdate.propTypes = {};
UserUpdate.defaultProps = {};


const mapStateToProps = (store) => ({});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UserUpdate);


