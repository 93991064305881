import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import { ActionButton } from '../../common';
import classnames from 'classnames';
import CustomizedSteppers from '../../layout/CustomizedSteppers';
import { useEffect, useState } from 'react';
import BasicDateRangePicker from '../../layout/DatePicker';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TextField from "@material-ui/core/TextField";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { Loading } from '../../elements';
import { convertDateToISOString } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    container: {
        '& .MuiDialog-paper': {
            maxHeight: 780,
            maxWidth: 800,
        },
        height: '100%',
    },
    title: {
        height: 160,
        // width: 798,
        margin: '-1px -1px -1px -1px',
        backgroundImage: 'url(/log_modal.png)',


        '& h2': {
            marginTop: 50,
            height: '100%',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            textShadow: "0 0 3px #101010",
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    title2: {
        cursor: 'pointer',
        marginLeft: '10px'
    },
    actions: {
        padding: 0,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-front',
    },
    buttonNavigation2: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '55px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    button: {
        width: 120,
        height: 40,
        marginLeft: 10,
        marginRight: 10,
        fontSize: 14,
    },
    button2: {
        width: 120,
        height: 40,
        marginLeft: 10,
        marginRight: 10,
        fontSize: 14,
    },
    content1: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    content2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    content3: {
        display: 'flex',
        flexDirection: 'row',
    },
    iconClose: {
        cursor: 'pointer',
        float: 'right',
        marginTop: '-50px',
        width: '20px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
    },
    attachment: {
        height: 120,
        padding: '15px',
    },
    attachmentEmtpy: {
        height: 120,
        padding: '15px',
    },
    attachmentContainer: {
        margin: theme.spacing(1),
        borderRadius: '10px',
    },
    place: {
        top: '-30px',
        width: 183,
        height: 60,
        color: '#fff',
        background: 'rgb(107, 187, 174)',
        borderRadius: '0px 0px 10px 10px',
        textAlign: 'center',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    message: {
        fontSize: 13,
    },
    iconBike: {
        marginLeft: '-20px',
        color: '#6BBBAE'
    },
}));

const NewReservation = (props) => {
    const {
        openNew,
        handleClose,
        handleSave,
        availableBikes,
        fetchAvailableBikes,
        places,
        codeLists,
        showDetail,
        detailValues,
    } = props;

    const classes = useStyles();
    const { t } = useTranslation();

    const steps = ['Callendar', 'Reservation', 'Confirm'];
    const [activeStep, setActiveStep] = useState(0);
    const [errorFrom, setErrorFrom] = useState(false);
    const [errorTo, setErrorTo] = useState(false);
    const [errorBike, setErrorBike] = useState(false);
    const [reservationName, setReservationName] = useState(false);
    const [bikesFetched, setBikesFetched] = useState(false);
    const [readyToSave, setReadyToSave] = useState(false);

    const [value, setValue] = useState([null, null]);
    const handleChange = (newValue) => {
        setValue(newValue);
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (!value || !value[0] || !value[1]) {
                setErrorFrom(true);
                setErrorTo(true);
            } else {
                setErrorFrom(false);
                setErrorTo(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            if (myBikes && myBikes.length > 0) {
                setErrorBike(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setErrorBike(true);
            }
        }

    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [place, setPlace] = useState('');
    const [bikeType, setBikeType] = useState('');
    const [bikeCategory, setBikeCategory] = useState('');
    const [bikeSize, setBikeSize] = useState('');

    const [myBikes, setMyBikes] = useState('');
    const [offeringBikes, setOfferingBikes] = useState('');

    const handleChangePlace = (event) => {
        setPlace(event.target.value);
    };
    const handleChangeBikeType = (event) => {
        setBikeType(event.target.value);
    };
    const handleChangeBikeCategory = (event) => {
        setBikeCategory(event.target.value);
    }
    const handleChangeBikeSize = (event) => {
        setBikeSize(event.target.value);
    }
    const handleBikeAdd = (bike) => {
        let tmp = [];
        if (myBikes) {
            tmp = myBikes.map((item) => {
                return { ...item };
            })
        }
        tmp.push(bike);

        setMyBikes(tmp);
    }
    const handleBikeRemove = (bike) => {
        if (activeStep !== 1) return;

        let tmp = [];
        if (myBikes) {
            myBikes.forEach(item => {
                if (item.bikeId != bike.bikeId) {
                    tmp.push({ ...item })
                };
            });
        }
        setMyBikes(tmp);

        tmp = [];
        if (offeringBikes) {
            tmp = offeringBikes.map((item) => {
                return { ...item };
            })
        }
        tmp.push(bike);

        setOfferingBikes(tmp);
    }

    const handleSaveLocal = () => {
        const dateFrom = convertDateToISOString(value[0]);
        const dateTo = convertDateToISOString(value[1]);
        handleSave({
            // date time zone offset calculation
            dateFrom: dateFrom,
            dateTo: dateTo,
            name: reservationName,
            bikes: { myBikes }
        })
    }

    const hadleClickName = (event) => {
        if (event) {
            setReservationName(event.target.value);
        }
    }

    const filterBikes = () => {
        if (bikesFetched) {
            let tmp = availableBikes.map((item) => {
                return { ...item };
            })

            if (place) {
                tmp = tmp.filter(item => item.stand.place.placeId === place);
            }
            if (bikeType) {
                tmp = tmp.filter(item => item.type === bikeType);
            }
            if (bikeCategory) {
                tmp = tmp.filter(item => item.category === bikeCategory);
            }
            if (bikeSize) {
                tmp = tmp.filter(item => item.size == bikeSize.split('_')[1]);
            }

            if (myBikes) {
                for (let i = 0; i < myBikes.length; i++) {
                    tmp = tmp.filter(item => myBikes[i].bikeId !== item.bikeId);
                }
            }

            setOfferingBikes(tmp);
        }
    }

    useEffect(() => {
        filterBikes();
    }, [place, bikeType, bikeCategory, bikeSize, myBikes, bikesFetched]);

    useEffect(() => {
        if (showDetail)
            setActiveStep(2);

        if (detailValues) {
            setValue(detailValues.date);
            setMyBikes(detailValues.myBikes);
        }
    }, [showDetail, detailValues]);

    useEffect(() => {
        if (!reservationName)
            setReservationName(t('CREATE_RESERVATION'));
    }, [reservationName]);

    useEffect(() => {
        if (!showDetail && value !== undefined && value !== null &&
            value[0] !== undefined && value[0] !== null &&
            value[1] !== undefined && value[1] !== null) {
            const dateFrom = value[0] instanceof Date ? convertDateToISOString(value[0]) : value[0];
            const dateTo = value[1] instanceof Date ? convertDateToISOString(value[1]) : value[1];
            setBikesFetched(false);
            setMyBikes([]);
            fetchAvailableBikes(dateFrom, dateTo).then(() => {
                setBikesFetched(true);
            });
        }
    }, [value]);

    const emptyMenuItem = () => {
        return (
            <MenuItem value="">
                <em>{t('NONE')}</em>
            </MenuItem>
        );
    }

    const renderMenuItemPlaces = () => {
        if (places) {
            return places.map((place) => (
                <MenuItem value={place.placeId}>{place.name}</MenuItem>
            ));
        }
    }

    const renderMenuItemCodeList = (type) => {
        if (codeLists) {
            for (let i = 0; i < codeLists.length; i++) {
                if (codeLists[i].code === type) {
                    return codeLists[i].items.map((item) => (
                        <MenuItem value={item.code}>{t(item.code)}</MenuItem>
                    ));
                }
            }
        }
    }

    const renderMyBikes = () => {
        if (myBikes && myBikes.length > 0) {
            return myBikes.map((bike) => (
                <Paper elevation={3} className={classes.attachmentContainer}>
                    <img src={`data:image/png;base64,${bike.img}`}
                        className={classes.attachment}
                        alt=""
                        onClick={() => { handleBikeRemove(bike) }}
                    />
                    <CheckCircleOutlineIcon className={classes.iconBike} />
                    <div className={classes.place}>{bike.name + ' ('+bike.size+'",'+bike.stand.place.name+')'}</div>
                </Paper>
            ));
        } else {
            return (
                <Paper elevation={0} className={classes.attachmentContainer}>
                    <div className={classes.attachment}>
                        <span className={errorBike ? classes.errorMessage : classes.message}></span>
                    </div>
                </Paper>
            );
        }
    }

    const renderOfferingBikes = () => {
        if (!bikesFetched) {
            return <Loading />;
        } else if (offeringBikes && offeringBikes.length > 0) {
            return offeringBikes.map((bike) => (
                <Paper elevation={3} className={classes.attachmentContainer}>
                    <img src={`data:image/png;base64,${bike.img}`}
                        className={classes.attachment}
                        alt=""
                        onClick={() => { handleBikeAdd(bike) }}
                    />
                    <RadioButtonUncheckedIcon className={classes.iconBike} />
                    <div className={classes.place}>{bike.name + ' ('+bike.size+'",'+bike.stand.place.name+')'}</div>
                </Paper>
            ));
        } else {
            return (
                <Paper elevation={3}>
                    <div className={classes.attachmentEmtpy}>
                        Dle zvolených kriterií nejsou v nabídce žádná kola.
                    </div>
                </Paper>
            );
        }
    }

    const renderFilter = (disabled) => {
        return (
            <>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="placeInput">{t('PLACE')}</InputLabel>
                    <Select
                        labelId="placeLabel"
                        id="place"
                        value={place}
                        onChange={handleChangePlace}
                        label="Place"
                        disabled={disabled}
                    >
                        {emptyMenuItem()}
                        {renderMenuItemPlaces()}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="bikeTypeInput">{t('BIKE_TYPE')}</InputLabel>
                    <Select
                        labelId="bikeTypeLabel"
                        id="bikeType"
                        value={bikeType}
                        onChange={handleChangeBikeType}
                        label="bikeType"
                        disabled={disabled}
                    >
                        {emptyMenuItem()}
                        {renderMenuItemCodeList('BI_T')}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="bikeCategoryInput">{t('BIKE_CATEGORY')}</InputLabel>
                    <Select
                        labelId="bikeCategoryLabel"
                        id="bikeCategory"
                        value={bikeCategory}
                        onChange={handleChangeBikeCategory}
                        label="bikeCategory"
                        disabled={disabled}
                    >
                        {emptyMenuItem()}
                        {renderMenuItemCodeList('BI_C')}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="bikeSize">{t('BIKE_SIZE')}</InputLabel>
                    <Select
                        labelId="bikeSizeLabel"
                        id="bikeSize"
                        value={bikeSize}
                        onChange={handleChangeBikeSize}
                        label="bikeSize"
                        disabled={disabled}
                    >
                        {emptyMenuItem()}
                        {renderMenuItemCodeList('BI_S')}
                    </Select>
                </FormControl>
            </>

        );
    }

    return (
        <Dialog
            maxWidth="lg"
            className={classes.container}
            open={openNew || showDetail}
            onClose={handleClose}
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
                {/* <HighlightOffIcon className={classes.iconClose} onClick={handleClose} /> */}
                <TextField
                    defaultValue={detailValues && detailValues.name ? detailValues.name : t('CREATE_RESERVATION')}
                    InputProps={{
                        disableUnderline: true,
                        style: { color: 'white' },
                        inputProps: {
                            style: { textAlign: 'center', fontSize: 32 },
                        }
                    }}
                    style={{ textShadow: "0 0 3px #101010" }}
                    disabled={showDetail || activeStep == 2}
                    onChange={hadleClickName}
                />

            </DialogTitle>

            <CustomizedSteppers
                activeStep={activeStep}
                steps={steps}
            />
            <DialogContent className={classes.content}>
                {activeStep === 0 &&
                    <div className={classes.content1}>
                        <BasicDateRangePicker
                            startText={t('BEGIN')}
                            endText={t('END')}
                            value={value}
                            handleChange={handleChange}
                            showStatic={true}
                            requiredFrom={true}
                            errorFrom={errorFrom}
                            requiredTo={true}
                            errorTo={errorTo}
                        />
                    </div>
                }
                {activeStep === 1 &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className={classes.content2}>
                                {renderFilter(false)}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {t('BIKE_CHOOSED')}
                            <div className={classes.content3}>
                                {renderMyBikes()}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {t('BIKE_OFFERING')}
                            <div className={classes.content3}>
                                {renderOfferingBikes()}
                            </div>
                        </Grid>
                    </Grid>
                }
                {activeStep === 2 &&
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div className={classes.content2}>
                                <BasicDateRangePicker
                                    startText={t('BEGIN')}
                                    endText={t('END')}
                                    value={value}
                                    handleChange={handleChange}
                                    showStatic={false}
                                    requiredFrom={true}
                                    errorFrom={errorFrom}
                                    requiredTo={true}
                                    errorTo={errorTo}
                                    disabled={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {t('BIKE_CHOOSED')}
                            <div className={classes.content3}>
                                {renderMyBikes()}
                            </div>
                        </Grid>
                    </Grid>
                }
            </DialogContent>

            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigation}>
                    {!showDetail &&
                        <ActionButton
                            disabled={activeStep <= 0}
                            action="back"
                            handleClick={handleBack}
                            className={classes.button2}
                        />
                    }
                </div>
                <div className={classes.buttonNavigation2}>
                    {activeStep < 2 &&
                        <ActionButton
                            disabled={(activeStep === 1 && !(myBikes.length > 0))}
                            action="next"
                            handleClick={handleNext}
                            className={classes.button2}
                        />
                    }
                    {activeStep === 2 && !showDetail &&
                        <ActionButton
                            action="save"
                            handleClick={handleSaveLocal}
                            className={classes.button2}
                        />
                    }
                </div>

            </DialogActions>
        </Dialog>
    );
}

export default NewReservation;